import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {get, delMail, readMail} from "../api";

export const getData = createAsyncThunk('user/getData', async (token) => {
    return await get(token)
})

export const updateData = createAsyncThunk('user/updateData', async (token) => {
    return await get(token)
})

export const delNotif = createAsyncThunk('user/delNotif', async ({token, id}) => {
    return await delMail(token, id)
})

export const readNotif = createAsyncThunk('user/readNotif', async ({token}) => {
    return await readMail(token)
})

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: {
            matches: {},
            notifications: false,
            mail: {},
            texts: {},
            scout: {},
            scoutsCat: {},
            balance: 0,
            topups: {}
        },
        error: null
    },
    extraReducers: {
        [readNotif.fulfilled]: (state, action) => {
            state.data.notifications = false;
        },
        [updateData.fulfilled]: (state, action) => {
            if(action.payload.status != 'error'){
                state.data = action.payload.data;
            }else{
                state.error = action.payload.status;
            }
            state.loading = false;
        },
        [getData.pending]: (state) => {
            state.loading = true;
        },
        [getData.fulfilled]: (state, action) => {
            if(action.payload.status != 'error'){
                state.data = action.payload.data;
            }else{
                state.error = action.payload.status;
            }
            state.loading = false;
        },
        [getData.rejected]: (state, action) => {
            state.loading = false;
        },
        [delNotif.pending]: (state) => {
            state.loading = true;
        },
        [delNotif.fulfilled]: (state, action) => {
            state.data.mail = action.payload.data.mail;
            state.loading = false;
        },
        [delNotif.rejected]: (state, action) => {
            state.loading = false;
        },
    }
});

export default userSlice.reducer;