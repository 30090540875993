import React, {useState} from 'react';
import PrimaryButton from "./PrimaryButton";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import closeIcon from "../../svg/close.svg";
import {useFormik} from "formik"
import {topUp} from "../../api";
import toast, {Toaster} from "react-hot-toast";
import SupportButton from "./SupportButton";

const Header = ({title}) => {
    const {data, loading} = useSelector(state => state.user)
    const [showModal, setShowModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            amount: ''
        },
        onSubmit: async (values) => {
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            const res = await topUp(formik.values.amount, localStorage.getItem("client-token"))
            if(res.status == 'success'){
                window.open(res.data.data.widget_url)
            }else{
                return toast.error("Ошибка!");
            }
        }
    });

    return (
        <>
            <SupportButton onClick={() => window.open('https://t.me/noscautcentr')}/>

            <div className={"flex justify-between items-center mb-3 mt-3 text-white border border-2 border-[#2E323A] bg-[#1E2026] py-3 px-3 rounded-md "}>
            <h1 className={"!mb-0 !mt-0 !ms-0"}>{title}</h1>
            <div>
                {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spinPulse className={"value"}/>
                ) : (
                    <>Баланс: {data && data.balance ? data.balance.toFixed(2) : 0}</>
                )
                }

                <PrimaryButton className={"ms-3"} onClick={async () => {
                    setShowModal(true)
                }}>Пополнить</PrimaryButton>
            </div>
        </div>
            {showModal && (
                <div class="nameofmatch2 text-white text-center">
                    <div>
                        <h1 className={"!ms-0 !mt-0"}>Пополнить счет</h1>


                        <form  onSubmit={formik.handleSubmit}  className="max-w-sm mx-auto">
                            <div className="">
                                <label htmlFor="email"
                                       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Укажите сумму в $ для пополнения:</label>
                                <input type="text" id="amount"
                                       onChange={formik.handleChange}
                                       value={formik.values.amount}
                                       className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block !w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                       required />
                            </div>
                            <PrimaryButton type={"submit"} className={"!mt-0"}>Перейти к пополнению</PrimaryButton>
                        </form>
                    </div>
                </div>
                )}
        </>
    );
};

export default Header;