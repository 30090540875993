import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";
import {io} from "socket.io-client";
import closeIcon from "../svg/close.svg";

import {getData} from "../api";
import {useSelector} from "react-redux";
import Menu from "./Components/Menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {delNotif, readNotif} from "../store/userSlice";
import Header from "./Components/Header";

const Notify = () => {
    const dispatch = useDispatch()
    const nav = useNavigate();
    const {data, loading} = useSelector(state => state.user)
    const token = localStorage.getItem("client-token")
    const [readed, setReaded] = useState([])

/*    async function fetchData() {
        const _data = await getData(localStorage.getItem("client-token"));
        if (_data.status !== "success") {
            localStorage.removeItem("client-token");
            nav("/auth");
        }
        setData(_data.data);
        if (localStorage.getItem("readed-notify")) {
            const read = JSON.parse(localStorage.getItem("readed-notify"))
            setReaded(read)
            for (let i in _data.data.mail) {
                if (!read.includes(_data.data.mail[i].text)) {
                    read.push(_data.data.mail[i].text)
                }
            }
            localStorage.setItem("readed-notify", JSON.stringify(read))
        } else {
            const read = []
            for (let i in _data.data.mail) {
                read.push(_data.data.mail[i].text)
            }
            localStorage.setItem("readed-notify", JSON.stringify(read))
        }
    }*/

    const closeNotif = async (id) => {
        await dispatch(delNotif({token, id}))
    }

    useEffect(() => {

        if (!localStorage.getItem("client-token")) {
            nav("/auth");
        }
        dispatch(readNotif({token}))
/*        const interv = setInterval(() => {
            fetchData();
        }, 5000);

        fetchData();

        return () => clearInterval(interv);*/
    }, []);

    return (
        <>
            <Toaster/>
            <div class="matches">
                <Menu/>
                <section className={"ms-5 me-5"} style={{width: "1000px"}}>
                    <Header title="Уведомления"/>
                    <div className={"listItems"}>
                        {data.mail.length > 0 && data.mail.map((item) => {
                            return (
                                <div className={"item"}>
                                    {item.text}
                                    <span>
                                        {new Date(item.date).toLocaleString()}
                                    </span>
                                    <FontAwesomeIcon icon={faClose} onClick={() => {
                                        closeNotif(item.id)
                                    }}/>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </div>
        </>
    );
};

export default Notify;
