import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";
import {io} from "socket.io-client";
import closeIcon from "../svg/close.svg";

import {buyScout, buyText, getData} from "../api";
import Menu from "./Components/Menu";
import {useSelector} from "react-redux";
import SecondaryButton from "./Components/SecondaryButton";
import PrimaryButton from "./Components/PrimaryButton";
import Header from "./Components/Header";
import SupportButton from "./Components/SupportButton";

const Texts = () => {
    const nav = useNavigate();
    const {data, loading} = useSelector(state => state.user)

/*
    async function fetchData() {
        const _data = await getData(localStorage.getItem("client-token"));
        if (_data.status !== "success") {
            localStorage.removeItem("client-token");
            nav("/auth");
        }
        setData(_data.data);
    }
*/

    useEffect(() => {
        if (!localStorage.getItem("client-token")) {
            nav("/auth");
        }

/*        const interv = setInterval(() => {
            fetchData();
        }, 5000);

        fetchData();

        return () => clearInterval(interv);*/
    }, []);

    const sendBuy = async (id) => {
        const r = await buyScout(localStorage.getItem("client-token"), id)
        if (r.error) {
            return alert(r.error)
        }
        window.open(r.data)
    }

    return (
        <>
            <Toaster/>

            <div class="matches">
                <Menu />
                <section className={"ms-5 me-5"} style={{width: "1000px"}}>
                    <Header title="Scout" />

                    <div className="mb-3">
                        {data.scoutsCat.length > 0 && data.scoutsCat.map((cat) => {
                            return (
                                <div className={"py-3 px-3 rounded-md bg-[#1E2026] border border-2 border-[#2E323A]  mb-5"}>
                                    <div
                                        className={"flex justify-between  text-white px-3 mb-5"}>
                                        <div className={"flex items-center"}>
                                            <h1 className={"text-4xl font-extrabold leading-none !ms-1 !mb-3 !mt-5"}>{cat.name}</h1>
                                        </div>
                                    </div>

                                    <div className={"grid grid-cols-4 gap-4 mb-3  px-3"}>

                                        {data.scout.length > 0 && data.scout
                                            .filter((scout) => scout.cat_id == cat.id)
                                            .map((item) => {
                                                return (
                                                    <div
                                                        className={"bg-gray-700 text-white p-3 cursor-pointer rounded-md flex justify-between flex-col"}
                                                    >
                                                        <div>
                                                            <div className={"text-center"}>{item.name}</div>
                                                            <div
                                                                className="text-red-600 text-xs mt-3">UPDATED: {item.date_update}</div>
                                                        </div>

                                                        <div className={"flex justify-between items-center mt-5"}>
                                                            <div>Price: {item.price}</div>
                                                            {item.buyed ? (
                                                                <SecondaryButton onClick={() => {
                                                                    sendBuy(item.id)
                                                                }}>SHOW</SecondaryButton>
                                                            ) : (
                                                                <PrimaryButton onClick={() => {
                                                                    if (window.confirm('Are you sure you wish to buy this item?'))
                                                                        sendBuy(item.id)
                                                                }
                                                                }>BUY</PrimaryButton>
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                    </div>
                                </div>

                            );
                        })}
                    </div>

                </section>
            </div>
        </>
    );
};

export default Texts;
