import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";
import {io} from "socket.io-client";
import closeIcon from "../svg/close.svg";

import {buyText, getData} from "../api";
import Menu from "./Components/Menu";
import {useSelector} from "react-redux";
import PrimaryButton from "./Components/PrimaryButton";
import Modal from "./Components/Modal";
import SecondaryButton from "./Components/SecondaryButton";
import Header from "./Components/Header";

const Texts = () => {
    const nav = useNavigate();
    const {data, loading} = useSelector(state => state.user)
    const [showModal, setShowModal] = useState(false)
    const [content, setContent] = useState('')

/*    async function fetchData() {
        const _data = await getData(localStorage.getItem("client-token"));
        if (_data.status !== "success") {
            localStorage.removeItem("client-token");
            nav("/auth");
        }
        setData(_data.data);
    }*/

    useEffect(() => {
        if (!localStorage.getItem("client-token")) {
            nav("/auth");
        }
/*
        const interv = setInterval(() => {
            fetchData();
        }, 5000);

        fetchData();

        return () => clearInterval(interv);*/
    }, []);

    const sendBuy = async (id) => {
        const r = await buyText(localStorage.getItem("client-token"), id)
        if (r.error) {
            return alert(r.error)
        }
        setContent(r.data)
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false);
        setContent('')
    };

    return (
        <>
            <Toaster/>
            <div class="matches">
                <Menu/>
                <section className={"ms-5 me-5"} style={{width: "1000px"}}>
                    <Header title="Bookmakers"/>
                    <div className={"ms-5 grid grid-cols-4 gap-4"}>
                        {data.texts.length > 0 && data.texts.map((item) => {
                            return (
                                <div
                                    className={"bg-gray-800 text-white p-3 cursor-pointer rounded-md flex justify-between flex-col"}>
                                    {item.name}

                                    <div className={"flex justify-between items-center mt-5"}>
                                        <div>Price: {item.price}</div>
                                        {item.buyed ? (
                                            <SecondaryButton onClick={() => {
                                                sendBuy(item.id)
                                            }}>SHOW</SecondaryButton>
                                        ) : (
                                            <PrimaryButton onClick={() => {
                                                if (window.confirm('Are you sure you wish to buy this item?'))
                                                    sendBuy(item.id)
                                            }
                                            }>BUY</PrimaryButton>
                                        )
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </section>

                <Modal show={showModal} onClose={closeModal}>
                    <div className="text-white p-5 text-center">
                        {content}
                        <div className="mt-6 flex justify-end">
                            <SecondaryButton onClick={closeModal}>Close</SecondaryButton>
                        </div>
                    </div>
                </Modal>

            </div>
        </>
    );
};

export default Texts;
