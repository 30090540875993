import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeadset} from "@fortawesome/free-solid-svg-icons";

const SupportButton = ({ className, onClick, type, children }) => {
    return (
        <button type={type}
                onClick={onClick}
                className={`fixed bottom-5 right-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-md px-5 py-3.5 focus:outline-none ${className}`}>
            <FontAwesomeIcon icon={faHeadset} />
        </button>
    );
};

export default SupportButton;